var $ = require('jquery');

// Initialize Google Maps API
var apiKey = 'AIzaSyAjtMkeDzECEex4YbrGZwLScpZR8nFzxcI';

var googlePromise = $.Deferred();

global.initGoogleMap = function () {
  googlePromise.resolve(google);
};

var script = document.createElement('script');
script.async = true;
script.defer = true;
script.src = 'https://maps.googleapis.com/maps/api/js?key=' + apiKey + '&callback=initGoogleMap';

document.body.appendChild(script);


// Map styles
var mapStyles = [
  {
    elementType: 'geometry',
    // stylers: [{color: "#212121"}]
    stylers: [{ color: '#272833' }]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{color: '#757585'}]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{color: '#212131'}]
    // stylers: [{color: "#282828"}]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [{color: '#757585'}]
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [{color: '#9e9eae'}]
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [{visibility: 'off'}]
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    // stylers: [{color: "#bdbdbd"}]
    stylers: [{color: '#9d9dad'}]
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{color: '#757585'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    // stylers: [{color: "#181818"}]
    stylers: [{color: '#3d3e4b'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{color: '#616171'}]
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [{color: '#1b1b2b'}]
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    // stylers: [{color: '#2c2c2c'}]
    stylers: [{color: '#1b1c23'}]
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [{color: '#8a8a9a'}]
  },
  {
    featureType: 'road',
    elementType: 'labels.icon',
    stylers: [{visibility: 'off'}]
    // stylers: [{lightness: '-75'}]
  },
  {
    featureType: 'poi',
    elementType: 'labels.icon',
    stylers: [{visibility: 'off'}]
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{visibility: 'off'}]
  },
  // {
  //   featureType: "road.arterial",
  //   elementType: "geometry",
  //   stylers: [{color: "#373737"}]
  // },
  // {
  //   featureType: "road.highway",
  //   elementType: "geometry",
  //   stylers: [{color: "#3c3c3c"}]
  // },
  // {
  //   featureType: "road.highway.controlled_access",
  //   elementType: "geometry",
  //   stylers: [{color: "#4e4e4e"}]
  // },
  // {
  //   featureType: "road.local",
  //   elementType: "labels.text.fill",
  //   stylers: [{color: "#616161"}]
  // },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [{color: '#757585'}]
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    // stylers: [{color: '#000000'}]
    stylers: [{color: '#111216'}]
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{color: '#3d3d4d'}]
  }
];

var mobileMapStyles = mapStyles.map(function (s) {
  var styles = [];
  styles.push('feature:' + (s.featureType || 'all'));
  styles.push('element:' + s.elementType);

  s.stylers.forEach(function (s) {
    for (var key in s) {
      styles.push(key + ':' + s[key].replace('#', '0x'));
    }
  });

  return styles.join('%7C');
}).map(function (s) { return '&style=' + s }).join('');


function getIconPath(location, path) {
  path = path || '/wp-content/themes/choctaw-casino/img/map-pins/gradients/';
  var basename = location.name.replace(/\s+/g, '-');

  if (location.name.indexOf('Casino Too') == 0) {
    basename = 'Casino-Too';
  }

  return path + encodeURIComponent(basename) + '.png';
}


function initLocationsMap(google, locations) {
  var mapContainer = document.querySelector('.locations-map .map-container');

  if (!mapContainer) return;

  var legend = locations.reduce(function (o, location) {
    var list = location.type == 'resort' ? o.resorts : o.casinos;

    list.push('<li><a href="' + location.url + '"><img src="' + getIconPath(location) + '" />' + location.name + '</a></li>');

    return o;
  }, { resorts: [], casinos: [] });


  var $mapKey = $('.locations-map .map-key');

  $mapKey.find('.list-unstyled').each(function (index) {
    $(this).html(legend[index == 0 ? 'resorts' : 'casinos']);
  });

  $mapKey.show();

  var map = new google.maps.Map(mapContainer, {
    zoom: 9,
    styles: mapStyles,
  });


  var infoWindow = new google.maps.InfoWindow();


  var markers = locations.filter(function (l) { return 'lat' in l && 'lng' in l }).map(function (location) {
    var marker = new google.maps.Marker({
      position: { lat: parseFloat(location.lat), lng: parseFloat(location.lng) },
      icon: {
        url: getIconPath(location),
        scaledSize: new google.maps.Size(29, 43),
        origin: new google.maps.Point(0, 0),
        // anchor: new google.maps.Point(13, 43),
      },
      map: map
    });

    marker.addListener('click', function () {
      infoWindow.setContent('<div><a href="' + location.url + '" class="location-name">' + location.name + '</a><br />' + location.address + '<br />' + location.city + ', ' + location.state + ' ' + location.zip + '<br />' + location.phone + '</div>');
      infoWindow.open(map, marker);
    });

    return marker;
  });


  var boundsIninitialized = false;

  function fitBounds() {
    if (boundsIninitialized) return;

  	var limits = new google.maps.LatLngBounds();

    markers.forEach(function (m) { limits.extend(m.position) });
    map.fitBounds(limits, { top: 10, right: 10, bottom: 10, left: 379 });

    boundsIninitialized = true;
  }

  var mediaQuery = window.matchMedia('(min-width: 768px)');

  if (mediaQuery.matches) {
    fitBounds();
  } else {
    mediaQuery.addListener(function (mediaQuery) {
      if (mediaQuery.matches) {
        fitBounds();
      }
    });
  }


  initMobileLocationsMap(locations);
}

function initMobileLocationsMap(locations) {
  locations = locations.filter(function (l) { return 'lat' in l && 'lng' in l });

  var $locationsMapMobile = $('.locations-map-mobile');

  function getMapWidth() {
    return $locationsMapMobile.width();
  }

  var markers = locations.map(function (l) {
    return {
      lat: l.lat,
      lng: l.lng,
      // icon: 'http://choctawcasinos.wpengine.com/wp-content/uploads/2019/01/' + (l.type == 'resort' ? 'blue' : 'red') + '-sm.png',
      icon: getIconPath(l, 'http://choctaw-web-assets.s3-website-us-east-1.amazonaws.com/'),
      color: l.type == 'resort' ? '0x1CA2F3' : '0xF4121A',
    }
  });

  var mobileMap = new MobileMap(markers, apiKey, mobileMapStyles);
  $(mobileMap.update(0, getMapWidth())).appendTo($locationsMapMobile);


  var mobileLocations = locations.map(function (location, index) {
    var destination = location.address + ', ' + location.city + ', ' + location.state + ' ' + location.zip;
    return '<a href="https://www.google.com/maps/dir/?api=1&destination=' + encodeURIComponent(destination)
      + '" target="_blank" class="location" data-index="' + index
      + '" style="background-image: url(\'' + location.image.url + '\');"><span>' + location.name + '</span><img src="' + getIconPath(location) + '" /></a>';
  });

  var $locations = $('<div class="locations">').html(mobileLocations.join('')).appendTo($locationsMapMobile);

  $locations.slick({
    infinite: false,
    speed: 300,
    variableWidth: true,
  });

  $locations.on('beforeChange', function (e, slick, currentSlide, nextSlide) {
    mobileMap.update(nextSlide, getMapWidth());
  });

  $locations.on('click', 'a', function (e) {
    var index = parseInt($(this).attr('data-index'), 10);

    if ($locations.slick('slickCurrentSlide') !== index) {
      e.preventDefault();
      $locations.slick('slickGoTo', index);
    }
  });

  $(window).on('resize', function (e) {
    mobileMap.update(mobileMap.currentIndex, getMapWidth());
  })
}


function MobileMap(locations, apiKey, mobileMapStyles) {
  this.locations = locations;
  this.apiKey = apiKey;
  this.mobileMapStyles = mobileMapStyles;
  this.currentIndex = 0;

  this.img = document.createElement('img');
  this.img.setAttribute('class', 'mobile-map');
}

MobileMap.prototype.update = function (index, width) {
  this.currentIndex = index;
  var location = this.locations[index];
  var icon = location.icon;
  var position = location.lat + ',' + location.lng;
  width = width || 640;

  var scale = Math.min(window.devicePixelRatio || 1, 2);

  if (scale > 1) {
    icon = icon.replace('.png', '@2x.png');
  }

  // var url = 'https://maps.googleapis.com/maps/api/staticmap?size=' + width + 'x450&zoom=11&markers=color:' + location.color + '%7C' + position + '&key=' + this.apiKey + this.mobileMapStyles;
  var url = 'https://maps.googleapis.com/maps/api/staticmap?size=' + width + 'x450&scale=' + scale + '&zoom=11&markers=scale:' + scale + '%7Cicon:' + icon + '%7C' + position + '&key=' + this.apiKey + this.mobileMapStyles;

  this.img.src = url;

  return this.img;
};


// var locationsPromise = $.Deferred(function (d) {
//   setTimeout(function () {
//     d.resolve(global.mapLocations);
//   }, 500)
// }).promise();

var locationsPromise = $.when(global.mapLocations);
var readyPromise = $.Deferred(function (d) {
  $(d.resolve);
});

$.when(googlePromise, locationsPromise, readyPromise).done(function (google, mapLocations) {
  initLocationsMap(google, mapLocations);
});
